import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { getPublicGroupList, getPublicGroupListMember } from 'library/api/groups';
import Card from 'library/common/commonComponents/Card';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Loader from 'library/common/commonComponents/Loader';
import Wrapper from 'library/common/commonComponents/Wrapper';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './publicGroups.module.scss';

const GroupNode = ({ group }) => {
  return (
    <div className={styles.groupItem}>
      <i className='fa fa-circle' />
      <Link className={styles.groupLink} to={`/groups/${group.id}`}>
        {group.groupName}
      </Link>
    </div>
  );
};

const KitaNode = ({ kita, groups, isChild = false, isOutside }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={!isChild ? 'kita-node-with-content' : ''}>
      <div
        className={isChild ? styles.kitaItem : styles.nodeHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {kita}
      </div>
      {(isOpen && isOutside !== true) && (
        <div className={styles.contentPanel}>
          {groups.map(group => (
            <GroupNode key={group.id} group={group} />
          ))}
        </div>
      )}
    </div>
  );
};

const CityNode = ({ city, institutions, isOutside }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sortedKitas = getSortedKitaNames(institutions);

  function getSortedKitaNames(data) {
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
      return []; // Return empty array if data is invalid
    }

    const keys = Object.keys(data);
    if (keys.length === 0) {
      return []; // Return empty array if no keys exist
    }

    return keys
      .filter(key => {
        const groupArray = data[key];
        return (
          Array.isArray(groupArray) &&
          groupArray.length > 0 &&
          groupArray[0] &&
          typeof groupArray[0] === 'object' &&
          groupArray[0].kitaName !== undefined
        );
      })
      .sort((a, b) => {
        const groupA = data[a][0];
        const groupB = data[b][0];

        const nameA = groupA?.kitaName || '';
        const nameB = groupB?.kitaName || '';

        return nameA.localeCompare(nameB);
      });
  }

  return (
    <div className='city-node'>
      <div className={styles.nodeHeader} onClick={() => setIsOpen(!isOpen)}>
        <i className={isOpen ? 'fa fa-minus' : 'fa fa-plus'} />
        {city}
      </div>
      {isOpen && (
        <div className={styles.contentPanel}>
          {sortedKitas.map(kita => {
            return (
              <KitaNode key={kita} kita={kita} groups={institutions[kita]} isChild={true} isOutside={isOutside} />
            );
          })}
        </div>
      )}
    </div>
  );
};

const GroupTreeView = ({ groups, isOutside }) => {
  const { withCity, withoutCity, only } = organizeAndSortGroups(groups);

  return (
    <div className='public-group-tree-view'>
      {!!Object.keys(withCity).length && (
        <>
          <Card className={styles.cardContainer}>
            <p className={styles.groupListTitle}>Entdecke hier deine AWO-Gruppen</p>
            {Object.keys(withCity).map(city => (
              <CityNode key={city} city={city} institutions={withCity[city]} isOutside={isOutside} />
            ))}
          </Card>
          <br />
        </>
      )}
      {/*<Card className={styles.cardContainer}>*/}
      {/*  <p className={styles.groupListTitle}>Gruppen in meinem Ortsverein</p>*/}
      {/*  {Object.keys(withoutCity).map(kita => (*/}
      {/*    <KitaNode key={kita} kita={kita} groups={withoutCity[kita]} isContentToggle={true} />*/}
      {/*  ))}*/}
      {/*</Card>*/}
      {/*{!!only.length && (*/}
      {/*  <>*/}
      {/*    <br />*/}
      {/*    <Card className={styles.cardContainer}>*/}
      {/*      {only.map(group => (*/}
      {/*        <GroupNode key={group} group={group} />*/}
      {/*      ))}*/}
      {/*    </Card>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

// Function to organize groups by city and kita
const organizeAndSortGroups = groups => {
  const organized = {
    withCity: {},
    withoutCity: {},
    only: [],
  };

  // Organize groups into the appropriate categories
  groups.forEach(group => {
    const { kitaName, kitaBelongedCity } = group;

    if (kitaBelongedCity) {
      // Group by city
      if (!organized.withCity[kitaBelongedCity]) {
        organized.withCity[kitaBelongedCity] = {};
      }

      // Group by kita
      if (!organized.withCity[kitaBelongedCity][kitaName]) {
        organized.withCity[kitaBelongedCity][kitaName] = [];
      }

      organized.withCity[kitaBelongedCity][kitaName].push(group);
    } else {
      if (kitaName) {
        // Group by kita if no city
        if (!organized.withoutCity[kitaName]) {
          organized.withoutCity[kitaName] = [];
        }
        organized.withoutCity[kitaName].push(group);
      } else {
        // Group undefined kita
        organized.only.push(group);
      }
    }
  });

  // Sort groups with city: sort cities first, then kitas within cities, and finally groups within kitas
  const sortedWithCity = Object.keys(organized.withCity)
    .sort((a, b) => a.localeCompare(b))  // Sort cities alphabetically
    .reduce((acc, city) => {
      const sortedKitas = Object.keys(organized.withCity[city])
        .reduce((kitaAcc, kita) => {
          // Sort groups
          kitaAcc[kita] = organized.withCity[city][kita].sort((a, b) => a.groupName.localeCompare(b.groupName));
          return kitaAcc;
        }, {});

      acc[city] = sortedKitas;
      return acc;
    }, {});

  // Sort groups without city (sort kitas and groups alphabetically)
  const sortedWithoutCity = Object.keys(organized.withoutCity)
    .sort((a, b) => a.localeCompare(b))  // Sort kitas
    .reduce((acc, kita) => {
      acc[kita] = organized.withoutCity[kita].sort((a, b) => a.groupName.localeCompare(b.groupName));  // Sort groups
      return acc;
    }, {});

  // Sort only kita groups
  organized.only.sort((a, b) => a.groupName.localeCompare(b.groupName));

  // Combine all organized data into a single structure
  return {
    withCity: sortedWithCity,
    withoutCity: sortedWithoutCity,
    only: organized.only,
  };
};

export default function PublicGroups({ isOutside, showBottomNotification, user, activeKita }) {
  useSiteTitle('Public groups');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPublicGroupList = useCallback(() => {
    setIsLoading(true);
    if (type === 'member') {
      getPublicGroupListMember().then(res => {
        setGroups(res.data);
        setIsLoading(false);
      });
    } else {
      getPublicGroupList().then(res => {
        setGroups(res.data);
        setIsLoading(false);
      });
    }
  }, [type]);

  useEffect(() => {
    fetchPublicGroupList();
  }, [fetchPublicGroupList]);

  return (
    <KitaPanelWrapper>
      <Wrapper>
        <div className='public-group-list-page'>
          {isLoading ? <Loader /> : <GroupTreeView groups={groups} isOutside={isOutside} />}
        </div>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
