import { useTranslation } from 'react-i18next';
import Button from 'library/common/commonComponents/Buttons/Button';
import React, { useEffect, useState } from 'react';
import HelpText from 'library/common/commonComponents/HelpText';
import {
  transferAllKidsToKitalino,
  saveOrUpdateKitalinoSettings,
  getKitalinoSettings,
} from 'library/api/kids';

import Input from 'library/common/commonComponents/Inputs/Input';
import styles from './kitalinoInterface.module.scss';
import Loader from 'library/common/commonComponents/Loader';

export default function KitalinoInterfaceSettings() {

  const [kitalinoSettings, setKitalinoSettings] = useState({
    userName: '',
    password: '',
    url: '',
    partnerClientKey: '',
    organizationId: '',
  });
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    getKitalinoSettings().then(data => {
      setKitalinoSettings(data.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const { t } = useTranslation();

  return (
    !isLoading ?
      <>
        <div className={styles.title}>{t('Administration.Kitalino settings')}</div>
        <table>
          <tr>
            <td>User-Name</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitalinoSettings.userName}
                onChange={({ target }) => {
                  setKitalinoSettings(prev => ({
                    ...prev,
                    userName: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Passwort</td>
            <td>
              <Input
                type='password'
                maxLength={500}
                value={kitalinoSettings.password}
                onChange={({ target }) => {
                  setKitalinoSettings(prev => ({
                    ...prev,
                    password: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>URL</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitalinoSettings.url}
                onChange={({ target }) => {
                  setKitalinoSettings(prev => ({
                    ...prev,
                    url: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Partner Client Key</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitalinoSettings.partnerClientKey}
                onChange={({ target }) => {
                  setKitalinoSettings(prev => ({
                    ...prev,
                    partnerClientKey: target.value,
                  }));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Organisations ID</td>
            <td>
              <Input
                type='text'
                maxLength={500}
                value={kitalinoSettings.organizationId}
                onChange={({ target }) => {
                  setKitalinoSettings(prev => ({
                    ...prev,
                    organizationId: target.value,
                  }));
                }}
              />
            </td>
          </tr>
        </table>
        <br />
        <Button
          type='primary'
          onClick={() => {
            saveOrUpdateKitalinoSettings(kitalinoSettings).finally(() => {
            });
          }}
        >{t('Administration.Save settings')}</Button>
        <br />
        <br />
        <div className={styles.text}>
          <HelpText>{t('Administration.Kitalino settings description')}</HelpText>
        </div>
        <Button
          onClick={() => {
            setIsLoading(true);
            transferAllKidsToKitalino().finally(() => {
              setIsLoading(false);
            });
          }}
        >{t('Administration.Export all kids to kitalino')}</Button>
      </> : <Loader />

  );
}
