import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from 'library/common/commonComponents/Buttons/UploadButton/UploadButtonContainer';
import Loader from 'library/common/commonComponents/Loader';
import { importAdebisGfz, inviteMultipleUsersToMultipleKitas } from 'library/api/user';
import { getKitasWhereUserIsAdmin } from 'library/api/kita';
import CheckBox from 'library/common/commonComponents/Checkbox';

import styles from './usersFrameImportPopup.module.scss';
import Button from 'library/common/commonComponents/Buttons/Button';

export default function AdebisGfzImport({ showBottomNotification }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [dontSendInvitationMail, setDontSendInvitationMail] = useState(false);
  const [blockUsersFromMessaging, setBlockUsersFromMessaging] = useState(false);
  const [hideUser, setHideUser] = useState(false);

  const [kitaList, setKitaList] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState([]);

  function updateTextboxState(id) {
    const updatedCheckboxList = kitaList.map(kita => {
      if (kita.value === id) {
        return { ...kita, checked: !kita.checked };
      }
      return kita;
    });

    setKitaList(updatedCheckboxList);
  }

  const uploadFile = files => {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('dontSendEmail', dontSendInvitationMail);
    formData.append('blockUsersFromMessaging', blockUsersFromMessaging);
    formData.append('hideUser', hideUser);

    setIsLoading(true);

    importAdebisGfz(formData)
      .then(data => {
        setSelectedUserEmails(data.data.emails);
        showBottomNotification(t('BottomNotifications.Success'));
        const errors = data.data.errors.map(error => {
          const msg = t(`ImportError.${error.key}`, error.args);
          return msg;
        });
        setErrorMessages(errors);
        getKitasWhereUserIsAdmin().then(data => {
          const mappedInstitutions = data.data.map(kita => {
            return { value: kita.id, label: `${kita.kitaName}`, checked: false };
          });
          setKitaList(mappedInstitutions);
        });
      })
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const inviteUsers = () => {
    const selectedKitas = kitaList.filter(kita => kita.checked).map(kita => kita.value);
    setIsLoading(true);

    inviteMultipleUsersToMultipleKitas(selectedUserEmails, selectedKitas)
      .catch(() => {
        showBottomNotification(t('BottomNotifications.Something went wrong'), {
          isFail: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <h2>{t('Administration.Adebis gfz import')}</h2>
      <br />
      <div>{t('Administration.Adebis gfz import description')}</div>
      <br />
      {!isLoading ? (
        <>
          <div className={styles.checkboxContainer}>
            <CheckBox
              className={styles.checkbox}
              name={t('Administration.Do not send Invitation Mail')}
              isChecked={dontSendInvitationMail}
              onChange={() => setDontSendInvitationMail(!dontSendInvitationMail)}
            />
          </div>
          <>
            <div className={styles.checkboxContainer}>
              <CheckBox
                className={styles.checkbox}
                name={t('Administration.Block users from messaging')}
                isChecked={blockUsersFromMessaging}
                onChange={() => setBlockUsersFromMessaging(!blockUsersFromMessaging)}
              />
            </div>
            <div className={styles.checkboxContainer}>
              <CheckBox
                className={styles.checkbox}
                name={t('Administration.Hide user')}
                isChecked={hideUser}
                onChange={() => setHideUser(!hideUser)}
              />
            </div>
            <br />
          </>
          <div>
            <UploadButton
              onChange={uploadFile}
              isMultiple={false}
              className={styles.uploadButton}
              allowedExtensions={['.xlsx']}
            >
              &nbsp;{t('Administration.Select and upload Adebis Excel file')}
            </UploadButton>
          </div>
        </>
      ) : (
        <div>
          {t('Administration.Upload is in progress, please wait until it finished')}
          <br />
          <Loader />
        </div>
      )}
      <br />
      <br />
      {kitaList.length > 0 && (
        <>
          {t('Administration.Select kitas where to add imported users')}
          <br />
          {kitaList.map(kita => {
            return (
              <CheckBox
                name={kita.label}
                isChecked={kita.checked}
                onChange={() => {
                  updateTextboxState(kita.value);
                }}
              />
            );
          })}
          <br />
          <Button onClick={() => inviteUsers()}>{t('AuthorizedPersons.Add users to kitas')}</Button>
          <br />
        </>
      )}
      <br />
      {errorMessages.length > 0 && (
        <>
          {t('Administration.Error messages')}
          <br />
          {errorMessages.map(data => {
            return <div key={data}>{data}</div>;
          })}
        </>
      )}
    </>
  );
}
